import React, {useEffect, useState} from 'react';
import Header from "./../components/header";
import AppModal from "../components/AppModal";
import 'react-toastify/dist/ReactToastify.css';
import {toast, ToastContainer} from "react-toastify";
import './../assets/style/modal.css';
import './../assets/style/dashboard.css';
import {Grid} from "@mui/material";
import Sidebar from "./../components/Sidebar";
import '../assets/style/adslist.css';
import PendingAdRow from "../components/PendingAdRow";
import {getAllAdsList, updateAdvertisementStatus} from '../api/services';
import VerifiedAdRow from '../components/VerifiedAdRow';
import AdsDocumentUpload from '../components/AdsDocumentUpload';
import DocumentListPopup from "../components/DocumentListPopup";

function AdsList() {
    const [adslistverified, setAdsListVerified] = useState([]);
    const [adslistpending, setAdsListPending] = useState([]);
    const [pendingpage, setPendingpage] = useState(true);
    const [verifiedpage, setVerifiedpage] = useState(false);
    const [documentModalOpen, setDocumentModalOpen] = useState(false);
    const [documentList, setDocumentList] = useState([]);
    const [selectedAdId, setSelectedAdId] = useState('');
    const [activeButton, setActiveButton] = useState('pending');

    const pending = () => {
        setActiveButton('pending')
        setPendingpage(true);
        setVerifiedpage(false);
    }

    const verified = () => {
        setActiveButton('verified')
        setPendingpage(false);
        setVerifiedpage(true);
    }

    let userData = JSON.parse(window.localStorage.getItem("userData"));
    const role = userData.role;

    const [advertisementId, setadvertisementId] = useState();
    const [popupUploadDocument, setpopupUploadDocument] = useState(false);
    const showDocumentUploadPopUp = (adsid) => {
        setpopupUploadDocument(true)
        setadvertisementId(adsid)
    };
    const closeDocumentUpload = () => {
        setpopupUploadDocument(false)
    }
    const openDocuments = (documents, adId) => {
        setDocumentModalOpen(true);
        setDocumentList(documents);
        setSelectedAdId(adId);
    };

    const closeDocuments = () => {
        setDocumentModalOpen(false);
        setReload();
    };

    const changeSwitchStatus = (index, status, adsid) => {
        let tempPendingList = [...adslistpending];
        tempPendingList[index].switchChecked = status;
        setAdsListPending(tempPendingList);
        let statusval = '';
        if (role === 'superadmin' && status === true){
            statusval = '3';
        } else if (role === 'superadmin' && status === false){
            statusval = '2';
        } else if (role === 'admin' && status === true){
            statusval = '2';
        } else if (role === 'admin' && status === false){
            statusval = '1';
        }
        updateAdvertisementStatus(adsid, statusval).then(
            response => {
                if (response.success === false) {
                    toast.error(response.data[0], {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
                else {
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }

                verifiedlistInit();
                pendinglistInit();
            }
        )
    };

    let adsPendingReturnFnc = (itemdata) => {
        if (role === 'superadmin' && itemdata.status === '2'){
            return false;
        } else if (role === 'superadmin' && itemdata.status === '3'){
            return true;
        } else if (role === 'admin' && itemdata.status === '1'){
            return false;
        } else {
            return false;
        }
    }


    useEffect(() => {
        if (window.localStorage.getItem("login")) {
            verifiedlistInit();
            pendinglistInit();
        }
    }, [])

    const page = 1;
    const vstatus = 'verified';
    const pstatus = 'pending';

    //get all verified ads
    const verifiedlistInit = () => {
        getAllAdsList(page, vstatus).then(
            response => {
                if (response.success) {
                    setAdsListVerified(response.data.advertisement);
                }
                else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
        )
    };

    const setReload = () => {
        verifiedlistInit();
        pendinglistInit();
    }

    //get all verified ads
    const pendinglistInit = () => {
        getAllAdsList(page, pstatus).then(
            response => {
                if (response.success) {
                    console.log(response.data);
                    if(response.data.advertisement != null){
                        response.data.advertisement.map(item => item['switchChecked'] = adsPendingReturnFnc(item));
                    }
                    setAdsListPending(response.data.advertisement);
                }
                else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
        )
    };

    return (
        <>
            <AppModal
                isOpen={popupUploadDocument}
                content={<AdsDocumentUpload closeModal={closeDocumentUpload} reLoad={setReload} dataid={advertisementId} />}
            />

            <AppModal
                isOpen={documentModalOpen}
                content={<DocumentListPopup onClose={closeDocuments} documents={documentList} advertiseId={selectedAdId}/>}
            />

            <Header />
            <Grid container sx={{ flexGrow: 1 }}>
                <Grid item xs={0} md={2.3} xl={1.7}>
                    <Sidebar />
                </Grid>
                <Grid item xs={12} md={9.7} xl={10.3}>
                    <div className='dashboard'>
                        <ToastContainer />
                        <div className="in15">
                            <div className='lft'>

                                <button onClick={pending} className={activeButton == 'pending' && 'active'}>Pending Ads</button>
                                <button onClick={verified} className={activeButton == 'verified' && 'active'}>Verified Ads</button>

                            </div>

                            <div className='rht'>
                                {pendingpage && <div className='addCard'>
                                    <div style={{ overflowY: "scroll", padding: '5px 10px' }}>
                                        { adslistpending && adslistpending.map((value,index) =>

                                            <div className='adsDetails boxad'>
                                                <div className='adsInfo'>
                                                    <div className='flexBtns'>
                                                        <button className='download' onClick={()=>showDocumentUploadPopUp(value.id)}>
                                                            Upload Documents
                                                        </button>
                                                        {
                                                            (() => {
                                                                if(role === 'superadmin' && value.status === '2'){
                                                                    return(
                                                                        <label className="switch">
                                                                            <input type="checkbox" checked={value.switchChecked} onChange={()=> {changeSwitchStatus(index, !value.switchChecked, value.id)}}/>
                                                                            <span className="slider round"/>
                                                                        </label>
                                                                    )
                                                                }else if(role === 'superadmin' && value.status === '3'){
                                                                    return(
                                                                        <label className="switch">
                                                                            <input type="checkbox" checked={value.switchChecked} onChange={()=>{changeSwitchStatus(index, !value.switchChecked, value.id)}}/>
                                                                            <span className="slider round"/>
                                                                        </label>
                                                                    )
                                                                }else if(role === 'admin' && value.status === '1'){
                                                                    return(
                                                                        <label className="switch">
                                                                            <input type="checkbox" checked={value.switchChecked} onChange={()=>{changeSwitchStatus(index, !value.switchChecked, value.id)}}/>
                                                                            <span className="slider round"/>
                                                                        </label>
                                                                    )
                                                                }
                                                            })()
                                                        }


                                                    </div>

                                                    <ul>
                                                        <li>Agent Name: {value.business_name}</li>
                                                        <li>Cost: &#8377; {value.cost}</li>
                                                        <li>From: {value.start_date}</li>
                                                        <li>To: {value.end_date}  </li>
                                                        <li>Current Status: {value.status_str}</li>
                                                        { value.document.length > 0 ?
                                                            <li>
                                                                <button style={{margin:"0px 10px", display:"inline-block"}} className='download' onClick={() => openDocuments(value.document, value.id)}>All Documents</button>
                                                                {value.application_form != null ? <a style={{margin:"0px 10px", display:"inline-block"}} title='Application Form' target='_blank' href={value.application_form} download className='download' >Application Form</a>:<></>}
                                                                {value.noc_form != null ? <a style={{margin:"0px 10px", display:"inline-block"}} title='NOC Form' target='_blank' href={value.noc_form} download className='download' >NOC Form</a>:<></>}
                                                            </li> : <></>
                                                        }

                                                    </ul>
                                                </div>
                                                <div className='adrRow'>
                                                    { value.locations && value.locations.map((advalue, adindex) =>
                                                        <PendingAdRow addata={advalue} index={adindex}/>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>}

                                {verifiedpage &&
                                <div className='addCard'>
                                    <div style={{ overflowY: "scroll", padding: '5px 10px' }}>
                                        { adslistverified && adslistverified.map((value, index) =>
                                            <div className='adsDetails boxad' style={{ position: "relative" }}>
                                                {value.is_current == 0?
                                                <div className="corner-tag" style={{ margin: "12px 14px" }}>Expired</div>:
                                                <div></div>
                                                }
                                                <div className='adsInfo'>
                                                    <ul>
                                                        <li>Business Name: {value.business_name}</li>
                                                        <li>Cost: &#8377; {value.cost}</li>
                                                        <li>From: {value.start_date}</li>
                                                        <li>To: {value.end_date}  </li>
                                                        { value.document.length > 0 ?
                                                            <li>
                                                                <button style={{margin:"0px 10px", display:"inline-block"}} className='download' onClick={() => openDocuments(value.document, value.id)}>All Documents</button>
                                                                {value.application_form != null ? <a style={{margin:"0px 10px", display:"inline-block"}} title='Application Form' target='_blank' href={value.application_form} download className='download' >Application Form</a>:<></>}
                                                                {value.noc_form != null? <a style={{margin:"0px 10px", display:"inline-block"}} title='NOC Form' target='_blank' href={value.noc_form} download className='download' >NOC Form</a>:<></>}
                                                            </li> : <></>
                                                        }
                                                    </ul>
                                                </div>
                                                <div className='adrRow'>
                                                    { value.locations && value.locations.map((advalue, adindex) =>
                                                        <VerifiedAdRow addata={advalue} index={adindex}/>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>}
                            </div>

                        </div>
                    </div >
                </Grid>
            </Grid>
        </>
    )
}

export default AdsList;
