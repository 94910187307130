import React, { useEffect, useState } from "react";
import Header from "./../components/header";
import "react-toastify/dist/ReactToastify.css";
import AppModal from "../components/AppModal";
import AddUser from "../components/AddUser";
import UserRow from "../components/UserRow";
import { getAllUserListService, getAllWards } from "../api/services";
import { toast, ToastContainer } from "react-toastify";
import "./../assets/style/modal.css";
import "./../assets/style/dashboard.css";
import AssignUser from "../components/AssignUser";
import { useNavigate } from "react-router-dom";
import Loader from "../components/loader";
import ButtonIcon from "../components/ButtonIcon";
import { Grid } from "@mui/material";
import Sidebar from "./../components/Sidebar";

function AppUser() {
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [ward, setWard] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const openModal = () => {
    setIsAddUserOpen(true);
  };

  const closeModal = () => {
    setIsAddUserOpen(false);
  };

  const showAddUserModal = () => {
    if (isAddUserOpen) closeModal();
    else openModal();
  };

  const [popupWardAssign, setPopupWardAssign] = useState(false);
  const showPopupWardAssign = () => {
    setPopupWardAssign(true);
  };
  const closePopupWardAssign = () => {
    setPopupWardAssign(false);
  };
  const getWards = () => {
    getAllWards().then((response) => {
      if (response.success) {
        setWard(response.data.ward);
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };
  useEffect(() => {
    if (window.localStorage.getItem("login")) {
      getAllUsers();
      getWards();
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } else {
      navigate("/");
    }
  }, []);

  //Service Call section Start
  const getAllUsers = () => {
    getAllUserListService().then((response) => {
      if (response.success) {
        console.log(response.data.user);
        setUsers(response.data.user);
      } else
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
    });
  };
  //Service Call section End

  let userData = JSON.parse(window.localStorage.getItem("userData"));
  const role = userData.role;

  return (
    <>
      <Header />
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid item xs={0} md={2.3} xl={1.7}>
          <Sidebar />
        </Grid>
        <Grid item xs={12} md={9.7} xl={10.3}>
          <div className="dashboard">
            <ToastContainer />
            {/*Create User Modal*/}
            <AppModal
              isOpen={isAddUserOpen}
              closeModal={closeModal}
              content={
                <AddUser
                  onUserAdded={(isUserAdded) => {
                    closeModal();
                    if (isUserAdded) {
                      getAllUsers();
                    }
                  }}
                  onCloseModal={() => closeModal()}
                />
              }
            />
            {/*Ward assign modal*/}
            <AppModal
              isOpen={popupWardAssign}
              content={
                <AssignUser
                  closeModal={closePopupWardAssign}
                  user={users}
                  ward={ward}
                  onSuccess={() => getAllUsers()}
                />
              }
            />
            {isLoading ? (
              <div>
                <Loader />
              </div>
            ) : (
              <div className="in">
                <div className="assign">
                  <p className="addsHeader">Registered App User</p>
                  <div className="btns">
                    <button onClick={showPopupWardAssign}>
                      <ButtonIcon />
                      Assign Ward
                    </button>
                    {role === "superadmin" && (
                      <button onClick={showAddUserModal}>
                        <ButtonIcon />
                        Add Application User
                      </button>
                    )}
                  </div>
                </div>

                <UserRow userDetails={users} onUserListUpdate={getAllUsers} />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default AppUser;
