import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "../assets/style/common.css";
import AppModal from "./AppModal";
import { deleteUser, getAllAdmins } from "../api/services";
import { toast } from "react-toastify";
import EditAdmin from "./EditAdmin";
import "../assets/style/table.css";
import editLogo from "../assets/images/pencil.png";
import deleteLogo from "../assets/images/delete.png";

function AdminRow({ data, onUserListUpdate }) {
  let userData = JSON.parse(window.localStorage.getItem("userData"));
  const [popupAdmin, setPopupAdmin] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState("");
  const showPopupAdmin = (admin) => {
    setSelectedAdmin(admin);
    setPopupAdmin(true);
  };
  const closePopupAdmin = () => {
    setPopupAdmin(false);
  };

  const adminInit = () => {
    getAllAdmins().then((response) => {
      if (response.success) {
        onUserListUpdate();
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const handleDeleteConfirmation = (deletedUserId) => {
    const result = window.confirm("This will delete the admin permanently.");
    if (result) {
      userDelete(deletedUserId);
    } else {
      console.log("User canceled");
    }
  };

  const userDelete = (userId) => {
    deleteUser(userId).then((response) => {
      if (response.success) {
        onUserListUpdate();
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  return (
    <>
      <div key={Math.random()}>
        <AppModal
          isOpen={popupAdmin}
          content={
            <EditAdmin
              initFunc={adminInit}
              closeModal={closePopupAdmin}
              admin={selectedAdmin}
            />
          }
        />

        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Ward</th>
              <th>Email</th>
              {userData.role === "superadmin" && (
                <>
                  <th>Phone</th>
                  <th>Password</th>
                  <th>Action</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((itemx, index) => (
                <tr key={index}>
                  <td>{itemx.first_name + " " + itemx.last_name}</td>
                  <td>{itemx.ward_name.join(", ")}</td>
                  <td>{itemx.email}</td>
                  {userData.role === "superadmin" && (
                    <>
                      <td>{itemx.phone}</td>
                      <td>{itemx.password}</td>
                      <td>
                        <button
                          className="custom-btn"
                          aria-label="Edit"
                          onClick={() => showPopupAdmin(itemx)}
                        >
                          <img
                            src={editLogo}
                            width={17}
                            height={17}
                            alt="Edit"
                          />
                        </button>
                        <button
                          className="custom-btn"
                          aria-label="Delete"
                          onClick={() => handleDeleteConfirmation(itemx.id)}
                        >
                          <img
                            src={deleteLogo}
                            width={17}
                            height={17}
                            alt="Delete"
                          />
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
export default AdminRow;
