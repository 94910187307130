import React, {useEffect, useState} from 'react';
import Header from "../components/header";
import 'react-toastify/dist/ReactToastify.css';
import {toast, ToastContainer} from "react-toastify";
import './../assets/style/modal.css';
import './../assets/style/dashboard.css';
import {Grid} from "@mui/material";
import Sidebar from "../components/Sidebar";
import '../assets/style/adslist.css';
import { getAllExistingAds } from '../api/services';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import UpdateIcon from '@mui/icons-material/Update';
import AppModal from "../components/AppModal";
import ExistingAdsUpdate from "../components/ExistingAdsUpdate";
import Download from "../components/Download";
import Loader from '../components/loader';

function ExisingAdList() {
    
    const [ads, setAds] = useState([]);
    const [locationDetailIds, setLocationDetailIds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [popupExistingAd, setPopupExistingAd] = useState(false);
    const [documentDownload, setDocumentDownload] = useState('');
    const [isDownloadOpen, setIsDownloadOpen] = useState(false);
    const navigate = useNavigate();

    const selectedLoction = (location_detail_id) => {
        let index = locationDetailIds.indexOf(location_detail_id);
        if (index > -1){
            setLocationDetailIds(locationDetailIds => {
                return locationDetailIds.filter((value, i) => i !== index)
            });
        }else{
            setLocationDetailIds([...locationDetailIds, location_detail_id]);
        }
    };
    const adsInit = () => {
        getAllExistingAds().then(
            response => {
                if (response.success) {
                    setAds(response.data.advertisement);
                }
                else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
        )
    };
    useEffect(() => {
        if (window.localStorage.getItem("login")) {
            adsInit();
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
        else {
            navigate("/")
        }

    }, []);

    const closeExistingAd = () => {
        setPopupExistingAd(false)
    }
    
    const closePopupWard = () => {
        setIsDownloadOpen(false)
    }
    const submitAds = (pdf) => {
        adsInit();
        setIsDownloadOpen(true);
        setDocumentDownload(pdf);
    }
    return (
        <>
            <AppModal top='30'
                isOpen={popupExistingAd}
                content={<ExistingAdsUpdate dataids={locationDetailIds} closeModal={closeExistingAd} reload={submitAds} />}
            />
            <AppModal isOpen={isDownloadOpen}
                                content={<Download buttonDownload={documentDownload} closeModal={closePopupWard} />
                                }
                            />
            <Grid container sx={{ flexGrow: 1 }}>
                <Grid item xs={0} md={2.3} xl={1.7}>
                    <Sidebar />
                </Grid>
                <Grid item xs={12} md={9.7} xl={10.3}>
                     <Header />
                    {isLoading ? (
                                    <div style={{textAlign:"center", paddingTop: "30px"}}> <Loader /> </div>
                                ) :
                    <div className='dashboard' style={{padding: "20px 0px"}}>
                        <ToastContainer />
                        <div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            {locationDetailIds.length === 0?<>
                                                <TableCell></TableCell>
                                                <TableCell>Ward name</TableCell>
                                                <TableCell>Location</TableCell>
                                                <TableCell>Image</TableCell>
                                            </>:
                                                <>
                                                    <TableCell></TableCell>
                                                    <TableCell><a href='javascript:void(0);' onClick={() => setPopupExistingAd(true)}  style={{textDecoration:'none'}} > <UpdateIcon fontSize='small' style={{marginBottom:'-5px'}}/> <span>Update advertisement</span></a></TableCell>
                                                </>}
                                           
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {ads.length > 0 ? ads.map((row) => (
                                            <TableRow
                                                key={row.location_detail_id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row"><input type='checkbox' onClick={() => {selectedLoction(row.location_detail_id)}} /></TableCell>
                                                <TableCell component="th" scope="row">Ward {row.ward_name}</TableCell>
                                                <TableCell component="th" scope="row">{row.location}</TableCell>
                                                <TableCell component="th" scope="row"><img src={row.image} height={200} /></TableCell>

                                            </TableRow>
                                        )):
                                                <TableCell component="th" scope="row" colSpan={4} style={{textAlign:'center'}}>No record found.</TableCell>

                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div >
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default ExisingAdList;
