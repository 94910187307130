import React from 'react';
import '../assets/style/adslist.css';

function PendingAdRow({ addata }) {
    return (
        <div>
           {addata && <div className="card">

                <div className="corner-tag">NEW</div>
                {/* {addata.location_detail_id && <img src={addata.image} alt='' className="card-image" />} */}
                <div className='details' style={{display:"inline-block", width: '15%'}}>
                    <img
                            src={addata.qrcode}
                            width={100}
                            height={100}
                            alt="Edit"
                          />
                </div>
                <div className='details' style={{display:"inline-block", width: '85%'}}>
                    <p className='adcode'><small>Ad Code : </small>{addata.advertisement_code}</p>
                    <p><small>Ward no : </small>{addata.ward_name}</p>
                    <p><small>Address : </small>{addata.location}</p>
                    {/* <p><small>Latitude : </small>{addata.latitude}</p>
                    <p><small>Longitude : </small>{addata.longitude}</p> */}
                </div>
            </div>}
        </div>
    );
}

export default PendingAdRow;