import React, { useState } from "react";
import { useEffect } from "react";
import Header from "../components/header";
import "./../assets/style/dashboard.css";
import { getAllWards, getAllAdmins } from "../api/services";
import Loader from "../components/loader";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddAdmin from "../components/AddAdmin";
import AppModal from "../components/AppModal";
import AssignAdmin from "../components/AssignAdmin";
import AdminRow from "../components/AdminRow";
import ButtonIcon from "../components/ButtonIcon";
import { Grid } from "@mui/material";
import Sidebar from "./../components/Sidebar";

const Admin = () => {
  //  Admin List
  const [admin, setAdmin] = useState([]);
  const [ward, setWard] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const adminInit = () => {
    getAllAdmins().then((response) => {
      if (response.success) {
        setAdmin(response.data.user);
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };
  const wardInit = () => {
    getAllWards().then((response) => {
      if (response.success) {
        setWard(response.data.ward);
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };
  useEffect(() => {
    if (window.localStorage.getItem("login")) {
      adminInit();
      wardInit();
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } else {
      navigate("/");
    }
  }, []);

  const [popupAdmin, setPopupAdmin] = useState(false);
  const showPopupAdmin = () => {
    setPopupAdmin(true);
  };
  const closePopupAdmin = () => {
    setPopupAdmin(false);
  };
  const [popupAdminAssign, setPopupAdminAssign] = useState(false);
  const showPopupAdminAssign = () => {
    setPopupAdminAssign(true);
  };
  const closePopupAdminAssign = () => {
    setPopupAdminAssign(false);
  };

  return (
    <>
      <Header />
      <Grid container sx={{ flexGrow: 1 }}>
        <Grid item xs={0} md={2.3} xl={1.7}>
          <Sidebar />
        </Grid>
        <Grid item xs={12} md={9.7} xl={10.3}>
          <div className="dashboard">
            <ToastContainer />
            <AppModal
              isOpen={popupAdmin}
              content={
                <AddAdmin initFunc={adminInit} closeModal={closePopupAdmin} />
              }
            />
            <AppModal
              isOpen={popupAdminAssign}
              content={
                <AssignAdmin
                  closeModal={closePopupAdminAssign}
                  admin={admin}
                  ward={ward}
                  refreshUi={adminInit}
                />
              }
            />

            {isLoading ? (
              <div>
                {" "}
                <Loader />{" "}
              </div>
            ) : (
              <div className="in">
                <div className="assign">
                  <p>Assign ward to a particular Admin</p>
                  <div className="btns">
                    <button onClick={showPopupAdminAssign}>
                      <ButtonIcon />
                      Assign to
                    </button>
                    <button onClick={showPopupAdmin}>
                      <ButtonIcon />
                      Create admin
                    </button>
                  </div>
                </div>

                <AdminRow data={admin} onUserListUpdate={adminInit} />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Admin;
