import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "../assets/style/common.css";
import { deleteUser } from "../api/services";
import { toast } from "react-toastify";
import EditAdmin from "./EditAdmin";
import AppModal from "./AppModal";
import "../assets/style/table.css";
import editLogo from "../assets/images/pencil.png";
import deleteLogo from "../assets/images/delete.png";

function UserRow({ userDetails, onUserListUpdate }) {
  const [popupUser, setPopupUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const showPopupUser = (user) => {
    setSelectedUser(user);
    setPopupUser(true);
  };
  const closePopupUser = () => {
    setPopupUser(false);
  };
  const handleDeleteConfirmation = (deletedUserId) => {
    const result = window.confirm("This will delete the user permanently.");
    if (result) {
      userDelete(deletedUserId);
    } else {
      console.log("User canceled");
    }
  };

  const userDelete = (userId) => {
    deleteUser(userId).then((response) => {
      if (response.success) {
        onUserListUpdate();
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  let userData = JSON.parse(window.localStorage.getItem("userData"));
  return (
    <>
      <div key={Math.random()}>
        <AppModal
          isOpen={popupUser}
          content={
            <EditAdmin
              initFunc={onUserListUpdate}
              closeModal={closePopupUser}
              admin={selectedUser}
            />
          }
        />
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Ward</th>
              <th>Email</th>
              {userData.role === "superadmin" && (
                <>
                  <th>Phone</th>
                  <th>Password</th>
                  <th>Action</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {userDetails &&
              userDetails
                .filter((user) => user.showinlist === 1)
                .map((itx) => (
                  <tr>
                    <React.Fragment key={itx.id}>
                      <td>{itx.first_name + " " + itx.last_name}</td>
                      <td>{itx.ward_name.length === 0 ? 'No Ward Assigned' : itx.ward_name.join(", ")}</td>
                      <td>{itx.email}</td>
                      {userData.role === "superadmin" && (
                        <>
                          <td>{itx.phone}</td>
                          <td>{itx.password}</td>
                                    <td>
                                        <div className="flexContainerBtn">
                            <button
                              className="custom-btn"
                              aria-label="Edit"
                              onClick={() => showPopupUser(itx)}
                            >
                              <img
                                src={editLogo}
                                width={17}
                                height={17}
                                alt="Edit"
                              />
                            </button>
                            <button
                              className="custom-btn"
                              aria-label="Delete"
                              onClick={() => handleDeleteConfirmation(itx.id)}
                            >
                              <img
                                src={deleteLogo}
                                width={17}
                                height={17}
                                alt="Delete"
                              />
                                            </button>
                                            </div>
                          </td>
                        </>
                      )}
                    </React.Fragment>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
export default UserRow;
