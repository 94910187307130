import { client } from "./client";

// Login User
export const doLogin = async (phone,password) => {
    try {
        const response = await client.post('/login', {
            "phone": phone,
            "password": password,
            "device_token": "token_web",
            "device_type": "web",
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};

// Fetch all Ward
export const getAllWards = async (page = 1, wardName='') => {
    if (!window.localStorage.getItem("login")){
        return {'success': false,'message': 'Invalid user'};
    }
    let userData = JSON.parse(window.localStorage.getItem("userData"));
    try {
        const response = await client.post('/ward/list', {
            "user_id": userData.id,
            "token": userData.token,
            "role": userData.role,
            "page": page,
            "ward_name": wardName,
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }

};
// Fetch all Existing Wards
export const getAllExistingAds = async (page = 1) => {
    if (!window.localStorage.getItem("login")){
        return {'success': false,'message': 'Invalid user'};
    }
    let userData = JSON.parse(window.localStorage.getItem("userData"));
    try {
        const response = await client.post('/advertisement/existing-advertisement-list', {
            "user_id": userData.id,
            "token": userData.token,
            "role": userData.role,
            "page": page,
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};

// Fetch all Existing Wards
export const getAllAdDetails = async (location_detail_ids) => {
    if (!window.localStorage.getItem("login")){
        return {'success': false,'message': 'Invalid user'};
    }
    let userData = JSON.parse(window.localStorage.getItem("userData"));
    try {
        const response = await client.post('/advertisement/existing-advertisement-details', {
            "user_id": userData.id,
            "token": userData.token,
            "role": userData.role,
            "location_detail_ids": location_detail_ids,
            //
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};

// Fetch all Ward
export const getAllAssemblies = async () => {
    if (!window.localStorage.getItem("login")){
        return {'success': false,'message': 'Invalid user'};
    }
    let userData = JSON.parse(window.localStorage.getItem("userData"));
    try {
        const response = await client.post('/assembly/list', {
            "user_id": userData.id,
            "token": userData.token,
            "role": userData.role,
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};

// Fetch all Admin
export const getAllAdmins = async (page=1) => {
    if (!window.localStorage.getItem("login")) {
        return { 'success': false, 'message': 'Invalid user' };
    }
    let userData = JSON.parse(window.localStorage.getItem("userData"));

    try {
        const response = await client.post('/user/list', {
            "user_id": userData.id,
            "token": userData.token,
            "role": userData.role,
            "search_role": 'admin',
            "page": page
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};

// Fetch all Agent
export const getAllAgent = async (page=1) => {
    if (!window.localStorage.getItem("login")) {
        return { 'success': false, 'message': 'Invalid user' };
    }
    let userData = JSON.parse(window.localStorage.getItem("userData"));

    try {
        const response = await client.post('/user/list', {
            "user_id": userData.id,
            "token": userData.token,
            "role": userData.role,
            "search_role": 'agent',
            "page": page
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};

//Get all assemblies
export const getAllAvailableAssemblies = async () => {
    if (!window.localStorage.getItem("login")) {
        return { 'success': false, 'message': 'Invalid user' };
    }
    let userData = JSON.parse(window.localStorage.getItem("userData"));
    try {
        const response = await client.post('assembly/list', {
            "user_id": userData.id,
            "token": userData.token,
            "role": 'superadmin',
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
}

// Add Ward
export const addWard = async (wardName = '', location, assemblyId) => {
    if (!window.localStorage.getItem("login")) {
        return { 'success': false, 'message': 'Invalid user' };
    }
    let userData = JSON.parse(window.localStorage.getItem("userData"));
    try {
        const response = await client.post('/ward/add', {
            "user_id": userData.id,
            "token": userData.token,
            "role": userData.role,
            "ward_name": wardName,
            "location": location,
            "assembly_id": assemblyId,
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};

// Add Admin
export const addAdmin = async (firstName, lastName, email, phone, password) => {
    if (!window.localStorage.getItem("login")) {
        return { 'success': false, 'message': 'Invalid user' };
    }
    try {
        const response = await client.post('/registration', {
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "phone": phone,
            "password": password,
            "role": 'admin',
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};

// Add User
export const addUserService = async (firstName, lastName, email, phone, password) => {
    if (!window.localStorage.getItem("login")) {
        return { 'success': false, 'message': 'Invalid user' };
    }
    try {
        const response = await client.post('/registration', {
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "phone": phone,
            "password": password,
            "role": 'user',
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};

// Assign Admin
export const assignWardToAdmin = async (wardId, AdminId) => {
    if (!window.localStorage.getItem("login")) {
        return { 'success': false, 'message': 'Invalid user' };
    }
    let userData = JSON.parse(window.localStorage.getItem("userData"));
    try {
        const response = await client.post('/ward/ward_assign', {
            "user_id": userData.id,
            "token": userData.token,
            "role": userData.role,
            "admin_id": AdminId,
            "ward_ids": [wardId],
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};

//Assign User
export const assignWardToUser = async (wardId, userId) => {
    if (!window.localStorage.getItem("login")) {
        return { 'success': false, 'message': 'Invalid user' };
    }
    let userData = JSON.parse(window.localStorage.getItem("userData"));
    try {
        const response = await client.post('/ward/ward_assign_user', {
            "user_id": userData.id,
            "token": userData.token,
            "role": userData.role,
            "assign_user_id": userId,
            "ward_ids": [wardId],
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
}

// Add Agent
export const addAgent = async (firstName, email, phone, password) => {
    if (!window.localStorage.getItem("login")) {
        return { 'success': false, 'message': 'Invalid user' };
    }
    try {
        const response = await client.post('/registration', {
            "first_name": firstName,
            "email": email,
            "phone": phone,
            "password": password,
            "role": 'agent',
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};

// Business Category List
export const businessCategoryList = async () => {
    if (!window.localStorage.getItem("login")) {
        return { 'success': false, 'message': 'Invalid user' };
    }
    let userData = JSON.parse(window.localStorage.getItem("userData"));
    try {
        const response = await client.post('/business_category/list', {
            "user_id": userData.id,
            "token": userData.token,
            "role": userData.role,
            "page": 1,
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};

// advertisement List
export const advertisementList = async () => {
    if (!window.localStorage.getItem("login")) {
        return { 'success': false, 'message': 'Invalid user' };
    }
    let userData = JSON.parse(window.localStorage.getItem("userData"));

    try {
        const response = await client.post('/advertisement/verification_list', {
            "user_id": userData.id,
            "token": userData.token,
            "role": userData.role,
           //"search_role": 'agent',
           //"page": page
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};

// Fetch all User
export const getAllUserListService = async (page= 1) => {
    if (!window.localStorage.getItem("login")) {
        return { 'success': false, 'message': 'Invalid user' };
    }
    let userData = JSON.parse(window.localStorage.getItem("userData"));

    try {
        const response = await client.post('/user/list', {
            "user_id": userData.id,
            "token": userData.token,
            "role": userData.role,
            "search_role": 'user',
            "page": page
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};

// Fetch all Ads List
export const getAllAdsList = async (page= 1, status) => {
    if (!window.localStorage.getItem("login")) {
        return { 'success': false, 'message': 'Invalid user' };
    }
    let userData = JSON.parse(window.localStorage.getItem("userData"));

    try {
        const response = await client.post('/advertisement/list', {
            "user_id": userData.id,
            "token": userData.token,
            "role": userData.role,
            "status": status,
            "page": page
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};

// Fetch all Wards for Super Admin
export const getAllWardsForSAdmin = async (page = 1, wardName='') => {
    if (!window.localStorage.getItem("login")){
        return {'success': false,'message': 'Invalid user'};
    }
    let userData = JSON.parse(window.localStorage.getItem("userData"));
    try {
        const response = await client.post('/ward/list', {
            "user_id": userData.id,
            "token": userData.token,
            "role": "superadmin",
            "page": page,
            "ward_name": wardName,
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};

// Add Agent
export const updateAdvertisementStatus = async (adsid, statusval) => {
    if (!window.localStorage.getItem("login")) {
        return { 'success': false, 'message': 'Invalid user' };
    }
    let userData = JSON.parse(window.localStorage.getItem("userData"));
    try {
        const response = await client.post('/advertisement/status-update', {
            "user_id": userData.id,
            "token": userData.token,
            "role": userData.role,
            "advertisement_id": adsid,
            "status": statusval,
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};

// Delete document
export const deleteAdvertisementDocument = async (advertiseId, documentId) => {
    if (!window.localStorage.getItem("login")) {
        return { 'success': false, 'message': 'Invalid user' };
    }
    let userData = JSON.parse(window.localStorage.getItem("userData"));
    try {
        const response = await client.post('/advertisement/document_delete', {
            "user_id": userData.id,
            "token": userData.token,
            "role": userData.role,
            "advertisement_id": advertiseId,
            "document_id": documentId,
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};


//Delete user
export const deleteUser = async (userId) => {
    if (!window.localStorage.getItem("login")) {
        return { 'success': false, 'message': 'Invalid user' };
    }
    let userData = JSON.parse(window.localStorage.getItem("userData"));
    try {
        const response = await client.post('/user/delete', {
            "user_id": userData.id,
            "token": userData.token,
            "role": 'superadmin',
            "deleted_user_id": userId
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
}

//Update User
export const updateUser = async ( updatedUserId, phone, password) => {
    if (!window.localStorage.getItem("login")) {
        return { 'success': false, 'message': 'Invalid user' };
    }
    let userData = JSON.parse(window.localStorage.getItem("userData"));
    try {
        const response = await client.post('/user/update', {
            "updated_user_id": updatedUserId,
            "phone": phone,
            "password": password,
            "user_id": userData.id,
            "token": userData.token,
            "role": 'superadmin',
        });
        return response.data;
    } catch (error) {
        throw new Error(error);
    }
};