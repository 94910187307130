import React, {useState} from 'react';
import '../assets/style/adslist.css';

function VerifiedAdRow({ addata }) {
    const [modalVisible, setModalVisible] = useState(false);
    const handleImageClick = () => {
        setModalVisible(true);
    };
    const closeModal = () => {
        setModalVisible(false);
    };
    return (
        <div>
            {addata.location_detail_id && <div className="card">
                { addata.location_detail_id && <img src={addata.image} alt='' className="card-image" onClick={handleImageClick}/>}
                {modalVisible && (
                    <div className="modal-overlay" onClick={closeModal}>
                        <div className="modal-content">
                            <img src={addata.image} alt="Zoomed Image" className="zoomed-image" />
                        </div>
                    </div>
                )}
                <div className='details' style={{margin: "0px 10px"}}>
                    <p className='adcode'><small>Ad Code : </small>{addata.advertisement_code}</p>
                    <p><small>Ward no : </small>{addata.ward_name}</p>
                    <p><small>Address : </small>{addata.location}</p>
                    <p><small>User Verified Address : </small>{addata.address}</p>
                    <p><small>Latitude : </small>{addata.latitude}</p>
                    <p><small>Longitude : </small>{addata.longitude}</p>
                </div>
               
                <div style={{padding:"10px"}}>
                    <iframe 
                        width="140" 
                        height="120" 
                        frameborder="0" 
                        scrolling="no" 
                        marginheight="0" 
                        marginwidth="0" 
                        src="https://maps.google.com/maps?q='+{addata.latitude}+','+{addata.longitude}+'&hl=es&z=14&amp;output=embed"
 >
 </iframe>  
                </div>
                 <div style={{padding:"10px 10px 10px 0px"}}>

                    <img
                        src={addata.qrcode}
                        width={80}
                        height={80}
                        alt="Edit"
                    />
                </div>
              
            </div>}
        </div>
    );
}

export default VerifiedAdRow;